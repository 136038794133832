<template>
  <div>
    <div class="form-group">
      <form @submit.prevent="trackCavStatus">
        <div class="card">
          <div class="card-body">
            <div class="form-inline">
              <input
                type="search"
                class="form-control col-auto"
                placeholder="App No (8-digit)"
                v-model="applicationNumber"
                maxlength="8"
                minlength="8"
                required
              />

              <button
                id="cav-status-tracker-btn"
                type="submit"
                class="btn btn-primary mx-2"
              >
                SUBMIT
              </button>
            </div>

            <p v-show="showReminder" class="text-danger mb-0 mt-2">
              Make sure to input a valid application number.
            </p>
          </div>
        </div>
      </form>
    </div>

    <div class="card">
      <div class="card-body">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td>App No</td>
              <th>{{ app.id }}</th>
            </tr>
            <tr>
              <td>Name</td>
              <th>
                {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
              </th>
            </tr>
            <tr>
              <td>Institution</td>
              <th class="text-uppercase">{{ app.hei_name }}</th>
            </tr>
            <tr>
              <td>Purpose</td>
              <th class="text-uppercase">{{ app.purpose }}</th>
            </tr>
            <tr>
              <td>Status</td>
              <th>{{ app.status }}</th>
            </tr>
          </tbody>
        </table>

        <p v-show="emptyResult" class="text-danger">No result found.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CavStatusTrackerMain',
  data() {
    return {
      applicationNumber: '',
      app: {},
      emptyResult: false,
      specialCharacters: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
      showReminder: false,
    };
  },
  methods: {
    trackCavStatus() {
      if (!this.isValidated(this.applicationNumber)) {
        this.showReminder = true;
        return;
      }

      this.showReminder = false;
      const btn = document.getElementById('cav-status-tracker-btn');
      btn.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span>`;
      btn.disabled = true;

      axios
        .get('api/cav-status-tracker/' + this.applicationNumber)
        .then((response) => {
          this.app = response.data;
          this.app ? (this.emptyResult = false) : (this.emptyResult = true);
          btn.innerHTML = 'SUBMIT';
          btn.disabled = false;
        })
        .catch((error) => {
          btn.innerHTML = 'SUBMIT';
          btn.disabled = false;
        });
    },
    isValidated(applicationNumber) {
      if (applicationNumber == null || applicationNumber == '') return;
      if (applicationNumber.length > 8) return;
      if (this.specialCharacters.test(applicationNumber)) return;
      if (/\s/.test(applicationNumber)) return;
      return true;
    },
  },
};
</script>
